<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
							<v-flex md12>
								<div class="d-flex">
									<v-chip
										class="mr-1 text-capitalize"
										color="cyan white--text"
										x-small
										v-if="this.singleDataInvoice && this.singleDataInvoice.barcode"
										>{{ this.singleDataInvoice.barcode }}
									</v-chip>
									<v-chip
										class="mr-1 text-capitalize"
										color="orange white--text"
										x-small
										v-if="
											this.singleDataInvoice &&
											this.singleDataInvoice.project &&
											this.singleDataInvoice.project.barcode
										"
										>{{ this.singleDataInvoice.project.barcode }}
									</v-chip>
									<p class="font-level-3-bold">
										<v-chip class="mb-1 mr-1" label :color="getStatusColor()" x-small outlined text-color="">
											{{ getStatusText() }}
										</v-chip>
									</p>
									<p class="font-level-3-bold" v-if="singleDataInvoice.direct_type == 'overdue-issue'">
										<v-chip class="mb-1 mr-1" label color="#191970" x-small outlined text-color="">
											Overdue For Issuance
										</v-chip>
									</p>
									<p class="font-level-3-bold" v-if="singleDataInvoice.direct_type == 'pending-issue'">
										<v-chip class="mb-1 mr-1" label color="red" x-small outlined text-color="">
											Pending For Issuance
										</v-chip>
									</p>
									<p class="font-level-3-bold" v-if="singleDataInvoice.actual_date">
										<v-chip class="mb-1 mr-1" label color="#008080" x-small outlined text-color="">
											Issued
										</v-chip>
									</p>
									<v-tooltip right content-class="" v-if="singleDataInvoice.extend_count">
										<template v-slot:activator="{ on, attrs }">
											<v-avatar color="red" size="27" v-bind="attrs" v-on="on">
												<span class="white--text fw-700" v-bind="attrs" v-on="on">
													E {{ singleDataInvoice.extend_count }}</span
												>
											</v-avatar>
										</template>
										<span> Extension </span>
									</v-tooltip>
									<!-- <v-avatar color="red" size="36">
                                         <span class="white--text fw-600" style="font-size: 18px;"> E {{ singleDataInvoice.extend_count }}</span>
                                        </v-avatar> -->
									<v-spacer></v-spacer>
									<v-btn
										color="blue darken-4 text-white"
										class="mr-2"
										depressed
										tile
										v-on:click="extendInvoice"
										><v-icon small left>mdi-more mdi-flip-h</v-icon>Extension
									</v-btn>
									<v-btn
										color="blue darken-4 text-white"
										class="mr-2"
										depressed
										tile
										v-on:click="editInvoice"
										><v-icon small left>mdi-pencil</v-icon>Edit
									</v-btn>
									<v-btn
										v-if="false"
										color="blue darken-4 text-white"
										class="mr-2"
										depressed
										tile
										v-on:click="addPayment"
										><v-icon small left>mdi-more mdi-flip-h</v-icon>Add Payment
									</v-btn>
									<v-btn depressed tile class="mr-2" v-on:click="goBack()">
										<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
										back
									</v-btn>
								</div>
								<p
									class="my-auto text-capitalize custom-heading"
									style="margin-top: -4px !important; font-size: 20px; font-weight: 600"
									v-if="
										singleDataInvoice.project &&
										singleDataInvoice.project &&
										singleDataInvoice.project.customer &&
										singleDataInvoice.project.customer.company_name
									"
								>
									{{ singleDataInvoice.project.customer.company_name }} :-
									{{ singleDataInvoice.project.customer.barcode }}
								</p>
								<div>
									<div
										class="row"
										v-if="
											singleDataInvoice.project &&
											singleDataInvoice.project &&
											singleDataInvoice.project.customer &&
											singleDataInvoice.project.customer.default_contact
										"
									>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Customer Name : </span>
											<span
												class="fw-600 primary--text"
												v-if="singleDataInvoice.project.customer.default_contact.contact_name"
											>
												{{ singleDataInvoice.project.customer.default_contact.contact_name }}</span
											>
											<template v-else> no customer name</template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Website : </span>
											<template v-if="singleDataInvoice.project.customer.website">
												<span class="fw-600 primary--text">{{
													singleDataInvoice.project.customer.website
												}}</span>
											</template>
											<template v-else> no website</template>
										</div>
										<div class="d-flex mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Email : </span>
											<template v-if="singleDataInvoice.project.customer.default_contact.email_address">
												<span
													class="fw-600 primary--text cursor-pointer"
													v-if="singleDataInvoice.project.customer.default_contact.email_address"
													v-on:click="
														copyNumber(singleDataInvoice.project.customer.default_contact.email_address)
													"
												>
													{{ singleDataInvoice.project.customer.default_contact.email_address }}</span
												>
											</template>
											<template v-else> no email </template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Phone Number : </span>
											<template v-if="singleDataInvoice.project.customer.default_contact.phone_number">
												<span
													class="fw-600 primary--text cursor-pointer"
													v-if="singleDataInvoice.project.customer.default_contact.phone_number"
													v-on:click="
														copyNumber(singleDataInvoice.project.customer.default_contact.phone_number)
													"
												>
													{{ singleDataInvoice.project.customer.default_contact.phone_number }}</span
												>
											</template>
											<template v-else> no phone number</template>
										</div>
									</div>
									<div class="row">
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Payable Amount : </span>
											<span class="fw-500" v-if="singleDataInvoice && singleDataInvoice.payable_amount">
												<v-chip
													label
													color="orange white--text"
													x-small
													v-if="singleDataInvoice && singleDataInvoice.payable_amount"
												>
													<span class="fw-500" v-if="singleDataInvoice && singleDataInvoice.payable_amount">
														{{ formatMoney(singleDataInvoice.payable_amount) }}</span
													>
												</v-chip>
											</span>
											<template v-else>
												<v-chip label color="orange white--text" x-small>
													<span class="fw-500"> $ 0.00</span>
												</v-chip>
											</template>
										</div>
										<div class="d-flex mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Paid Amount : </span>
											<span class="fw-500" v-if="singleDataInvoice && singleDataInvoice.paid_amount">
												<v-chip
													label
													color="green white--text"
													x-small
													v-if="singleDataInvoice && singleDataInvoice.paid_amount"
												>
													<span class="fw-500" v-if="singleDataInvoice && singleDataInvoice.paid_amount">
														{{ formatMoney(singleDataInvoice.paid_amount) }}</span
													>
												</v-chip>
											</span>
											<template v-else>
												<v-chip label color="green white--text" x-small>
													<span class="fw-500"> $ 0.00</span>
												</v-chip>
											</template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Balance Amount : </span>
											<span class="fw-500" v-if="singleDataInvoice && singleDataInvoice.paid_amount">
												<v-chip label color="red white--text" x-small>
													<span class="fw-500" v-if="singleDataInvoice && pendingBalance() > 0">
														{{ pendingBalance() }}</span
													>
													<span class="fw-500" v-else>{{ pendingBalance() }} </span>
												</v-chip>
											</span>
											<template v-else>
												<v-chip label color="red white--text" outlined text-color="" x-small>
													<span class="fw-500">
														{{
															formatMoney(singleDataInvoice.payable_amount - singleDataInvoice.paid_amount)
														}}</span
													>
												</v-chip>
											</template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Billing Type : </span>
											<span class="fw-500">
												<span
													v-if="
														singleDataInvoice &&
														singleDataInvoice.project &&
														singleDataInvoice.project.billing_type == 'single_billing'
													"
												>
													<Chip class="mr-1" outlined text="Single Billing" text-color="" color="blue"></Chip>
												</span>
												<span v-else>
													<Chip
														class="mr-1"
														outlined
														text="Multiple Billing"
														text-color=""
														color="orange"
													></Chip>
												</span>
											</span>
										</div>
									</div>
									<div class="row">
										<div class="mt-2 py-0 col-md-3 col-12 d-flex">
											<div class="fw-600 mr-2" style="min-width: 8rem">Service Type :</div>
											<div v-if="singleDataInvoice" class="fw-500" style="min-width: 17rem">
												<template v-if="singleDataInvoice.seo">
													<Chip text="SEO" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
												<template v-if="singleDataInvoice.domain">
													<Chip text="D" color="red" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
												<template v-if="singleDataInvoice.web">
													<Chip text="W" color="orange" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
												<template v-if="singleDataInvoice.ssl">
													<Chip text="SSL" color="green" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
												<template v-if="singleDataInvoice.hostting">
													<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
												<template v-if="singleDataInvoice.maintenance">
													<Chip text="M" color="cyan" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
												<template v-if="singleDataInvoice.software">
													<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</template>
											</div>
										</div>
									</div>
								</div>
							</v-flex>
						</v-layout>
						<v-layout class="px-4 py-2">
							<v-tabs
								v-model="invoiceTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<v-tab href="#overview"
									><v-icon small left>mdi-file-document-multiple</v-icon>Overview</v-tab
								>
								<v-tab href="#file"><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
								<v-tab href="#extend-activity" v-on:click="invoiceExtendActivity()"
									><v-icon small left>mdi-wrench-outline</v-icon>Extension Activity</v-tab
								>
								<v-tab href="#activity" v-on:click="invoiceActivity()"
									><v-icon small left>mdi-yoga</v-icon>Activity</v-tab
								>
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content px-4">
						<v-tabs-items v-model="invoiceTab">
							<v-tab-item value="overview">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<table class="w-70">
											<!-- <tr>
												<td class="text-muted fw-500 px-3 py-2">Company UEN</td>
												<td class="fw-600 px-3 py-1">
													<span
														class="fw-500"
														v-if="salesPerson"
													>
												
														<div v-for="(row,parent) in salesPerson" :key="parent">
															<pre>{{ row }}</pre>
															<v-chip
															class="mb-1 mr-1"
															small
															v-for="(data, index) in row.attendees_id"
															:key="index"
														>
														
															<v-avatar left small>
																
																<img v-if="data.profile_img" :src="data.profile_img" />
																<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
															</v-avatar>
															<span v-if="data && data.first_name">{{ data.first_name }}</span>
														</v-chip>
														</div>
													</span>
												</td>
											</tr> -->
											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Company UEN</td>
												<td class="fw-600 px-3 py-1">
													<template
														v-if="
															singleDataInvoice &&
															singleDataInvoice.project &&
															singleDataInvoice.project.customer &&
															singleDataInvoice.project.customer.company_uen
														"
													>
														{{ singleDataInvoice.project.customer.company_uen }}</template
													>
													<template v-else> <em class="text-muted">no company uen</em></template>
												</td>
											</tr>

											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Industry</td>
												<td class="fw-600 px-3 py-1">
													<template
														v-if="
															singleDataInvoice &&
															singleDataInvoice.project &&
															singleDataInvoice.project.customer &&
															singleDataInvoice.project.customer.industry
														"
													>
														{{ singleDataInvoice.project.customer.industry }}</template
													>
													<template v-else> <em class="text-muted">no industry</em></template>
												</td>
											</tr>

											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Customer Remark</td>
												<td class="fw-600 px-3 py-1" style="text-transform: capitalize">
													<template
														v-if="
															singleDataInvoice &&
															singleDataInvoice.project &&
															singleDataInvoice.project.customer &&
															singleDataInvoice.project.customer.remark
														"
													>
														{{ singleDataInvoice.project.customer.remark }}</template
													>
													<template v-else> <em class="text-muted">no remark</em></template>
												</td>
											</tr>
											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Contract #</td>
												<td class="fw-600 px-3 py-1">
													<template
														v-if="
															singleDataInvoice &&
															singleDataInvoice.project &&
															singleDataInvoice.project.contract &&
															singleDataInvoice.project.contract.barcode
														"
													>
														{{ singleDataInvoice.project.contract.barcode }}</template
													>
												</td>
											</tr>

											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Contract Title</td>
												<td class="fw-600 px-3 py-1">
													<template
														v-if="
															singleDataInvoice &&
															singleDataInvoice.project &&
															singleDataInvoice.project.contract &&
															singleDataInvoice.project.contract.title
														"
													>
														{{ singleDataInvoice.project.contract.title }}</template
													>
													<template v-else> <em class="text-muted">no title</em></template>
												</td>
											</tr>

											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Contract Status</td>
												<td class="fw-600 px-3 py-1" style="text-transform: capitalize">
													<template
														v-if="
															singleDataInvoice &&
															singleDataInvoice.project &&
															singleDataInvoice.project.contract &&
															singleDataInvoice.project.contract.status
														"
													>
														<span class="fw-500">
															<template
																v-if="
																	singleDataInvoice &&
																	singleDataInvoice.project &&
																	singleDataInvoice.project.contract &&
																	singleDataInvoice.project.contract.status == 1
																"
															>
																<Chip class="mr-2 mb-1" outlined text="Draft" text-color="" color="cyan"></Chip>
															</template>
															<template
																v-if="
																	singleDataInvoice &&
																	singleDataInvoice.project &&
																	singleDataInvoice.project.contract &&
																	singleDataInvoice.project.contract.status == 2
																"
															>
																<Chip class="mr-2 mb-1" outlined text="Accepted" text-color="" color="green"></Chip>
															</template>
															<template
																v-if="
																	singleDataInvoice &&
																	singleDataInvoice.project &&
																	singleDataInvoice.project.contract &&
																	singleDataInvoice.project.contract.status == 3
																"
															>
																<Chip class="mr-2 mb-1" outlined text="Rejected" text-color="" color="red"></Chip>
															</template>
														</span>
													</template>
												</td>
											</tr>
											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Invoice Date</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="singleDataInvoice && singleDataInvoice.actual_date">
														{{ formatDate(singleDataInvoice.actual_date) }}</template
													>
													<template v-else> <em class="text-muted">no invoice date</em></template>
												</td>
											</tr>
											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Add Date</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="singleDataInvoice && singleDataInvoice.start_date">
														<v-chip label color="red white--text" outlined text-color="" x-small>
															<span class="fw-500"> {{ addDate() }}</span>
														</v-chip>
													</template>
													<template v-else> <em class="text-muted">no add date</em></template>
												</td>
											</tr>
											<tr>
												<td class="text-muted fw-500 px-3 py-2 w-20">Due Date</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="singleDataInvoice && singleDataInvoice.start_date">
														<v-chip label color="green white--text" outlined text-color="" x-small>
															<span class="fw-500"> {{ formatDate(singleDataInvoice.start_date) }}</span>
														</v-chip>
													</template>
													<template v-else> <em class="text-muted">no add date</em></template>
													<!-- <template v-if="singleDataInvoice && singleDataInvoice.start_date">
														{{ formatDate(singleDataInvoice.start_date) }}</template
													>
													<template v-else> <em>no dur date</em></template> -->
												</td>
											</tr>
										</table>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item value="file">
								<Files
									v-if="invoiceTab == 'file'"
									class="mx-4"
									type="invoice"
									:url="'invoice'"
									:type-id="invoiceId"
								>
								</Files>
							</v-tab-item>
							<v-tab-item value="extend-activity">
								<template>
									<div class="overflow-y" style="max-height: calc(100vh - 306px)">
										<table width="100%" class="detail-table table-head-sticky">
											<thead>
												<tr>
													<th class="p-2 light-blue-bg custome-table">Sr.No.</th>
													<th class="p-2 light-blue-bg custome-table">Extension / Extension Type</th>
													<th class="p-2 light-blue-bg custome-table">Old Date</th>
													<th class="p-2 light-blue-bg custome-table">Extended Date</th>
													<th class="p-2 light-blue-bg custome-table">Extend Period</th>
													<th class="p-2 light-blue-bg custome-table">Reason</th>
													<th class="p-2 light-blue-bg custome-table">Remark</th>
													<!-- <th class="p-2 light-blue-bg custome-table">Duration</th> -->
													<th class="p-2 light-blue-bg custome-table">Added By</th>
													<th class="p-2 light-blue-bg custome-table">Created At</th>
												</tr>
											</thead>
											<tbody v-if="ExtendlogData.length > 0">
												<tr v-for="(row, index) in ExtendlogData" :key="index">
													<!--   <pre>   {{ row }}</pre> -->
													<td class="p-2 border-top-light-grey custome-table-td">
														{{ index + 1 }}
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<v-avatar color="red" size="36" class="mr-2">
															<span class="white--text fw-600" style="font-size: 18px"> E {{ index + 1 }}</span>
														</v-avatar>
														<span>
															<v-chip
																label
																color="green white--text"
																outlined
																text-color=""
																x-small
																v-if="row && row.select_type == 'single'"
															>
																<span class="fw-500" v-if="row && row.select_type == 'single'">
																	Single Invoice</span
																>
															</v-chip>
															<v-chip
																label
																color="green white--text"
																outlined
																text-color=""
																x-small
																v-if="row && row.select_type == 'multiple'"
															>
																<span class="fw-500" v-if="row && row.select_type == 'multiple'">
																	Multiple Invoice</span
																>
															</v-chip>
														</span>
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<span v-if="row && row.old_date">
															<v-chip
																label
																color="red white--text"
																outlined
																text-color=""
																x-small
																v-if="row && row.old_date"
															>
																<span class="fw-500" v-if="row && row.old_date">
																	{{ addOldDate(row.old_date) }}</span
																>
															</v-chip>
															<div>
																<v-chip
																	label
																	color="green white--text"
																	outlined
																	text-color=""
																	x-small
																	v-if="row && row.old_date"
																>
																	<span class="fw-500" v-if="row && row.old_date">
																		{{ formatDate(row.old_date) }}</span
																	>
																</v-chip>
															</div>
														</span>
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<span v-if="row && row.extend_date">
															<v-chip
																label
																color="red white--text"
																outlined
																text-color=""
																x-small
																v-if="row && row.extend_date"
															>
																<span class="fw-500" v-if="row && row.extend_date">
																	{{ addExtendDate(row.extend_date) }}</span
																>
															</v-chip>
															<div>
																<v-chip
																	label
																	color="green white--text"
																	outlined
																	text-color=""
																	x-small
																	v-if="row && row.extend_date"
																>
																	<span class="fw-500" v-if="row && row.extend_date">
																		{{ formatDate(row.extend_date) }}</span
																	>
																</v-chip>
															</div>
														</span>
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<div v-if="row.time_period == 'month'">
															<v-chip class="mb-1 mr-1" label color="black" x-small outlined text-color="">
																{{ row.duration }} (Months)
															</v-chip>
														</div>
														<div v-if="row.time_period == 'day'">
															<v-chip class="mb-1 mr-1" label color="black" x-small outlined text-color="">
																{{ row.duration }} (Days)
															</v-chip>
														</div>
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<span v-if="row && row.reason" class="text-capitalize">
															{{ row.reason.replace("-", " ") }}
														</span>
														<em class="text-muted" v-else>no reason</em>
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<span v-if="row && row.remark" class="text-capitalize">
															{{ row.remark }}
														</span>
														<em class="text-muted" v-else>no remark</em>
													</td>
													<td class="p-2 border-top-light-grey custome-table-td">
														<span v-if="row && row.added_by">
															<v-chip class="mb-1 mr-1" small
																><v-avatar left small>
																	<img v-if="row.profile_img" :src="row.profile_img" />
																	<img
																		v-else
																		src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																	/>
																</v-avatar>
																<span v-if="row && row.display_name">{{ row.display_name }}</span>
															</v-chip>
														</span>
													</td>

													<td class="p-2 border-top-light-grey custome-table-td">
														<v-chip label color="green white--text" outlined text-color="" x-small>
															<span class="fw-500"> {{ row.added_at }}</span>
														</v-chip>
													</td>
												</tr>
											</tbody>
											<tfoot v-else>
												<tr>
													<td colspan="7">
														<p class="m-0 row-not-found text-center py-3">
															<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
															Uhh... There
															<span class="text-lowercase">are no extension activity at the moment.</span>
														</p>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</template>
								<!-- <div v-for="(logs, index) in ExtendlogData" :key="index">
									<ActivityTab :open_details="true" :log="logs" title="Invoice"></ActivityTab>
								</div> -->
							</v-tab-item>
							<v-tab-item value="activity">
								<div class="py-3">
									<div class="fs-16 fw-500 primary--text">Upcoming</div>
								</div>
								<div v-for="(logs, index) in logData" :key="index">
									<ActivityTab :open_details="true" :log="logs" title="Invoice"></ActivityTab>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
		</div>
		<UpdateInvoice
			v-if="UpdateInvoiceDialog"
			:customer-data-id="customerId"
			v-on:success="(UpdateInvoiceDialog = false), singleInvoice()"
			:updated-data="singleDataInvoice"
			v-on:close="UpdateInvoiceDialog = false"
			:update-invoice-dialog="UpdateInvoiceDialog"
		>
		</UpdateInvoice>
		<!-- <AddPaymentDialog
			v-if="UpdateInvoiceDialog"
			:customer-data-id="customerId"
			v-on:success="(UpdateInvoiceDialog = false), singleInvoice()"
			:updated-data="singleDataInvoice"
			v-on:close="UpdateInvoiceDialog = false"
			:update-invoice-dialog="UpdateInvoiceDialog"
		>
		</AddPaymentDialog> -->
		<ExtendInvoice
			v-if="ExtendInvoiceDialog"
			:customer-data-id="customerId"
			v-on:success="refreshFunction"
			:updated-data="singleDataInvoice"
			v-on:close="ExtendInvoiceDialog = false"
			:add-date="addDate"
			:extend-invoice-dialog="ExtendInvoiceDialog"
			:project-id="singleDataInvoice.project?.id"
			:project-data="singleDataInvoice.project"
		>
		</ExtendInvoice>
		<!-- sidebar drawers -->
	</v-sheet>
</template>
<script>
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import { toSafeInteger } from "lodash";
import { GET } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import Files from "@/view/components/Files";
import Chip from "@/view/components/Chip";
import MomentJS from "moment-timezone";
import UpdateInvoice from "@/view/module/components/update-invoice";
// import AddPaymentDialog from "@/view/module/components/AddPayment";
import ExtendInvoice from "@/view/module/components/Extend-Invoice";

export default {
	name: "Invoice-details",
	data() {
		return {
			open_dialog: {},
			singleDataInvoice: {},
			salesPerson: [],
			open_detail: true,
			logData: [],
			ExtendlogData: [],
			invoiceId: 0,
			customerId: 0,
			UpdateInvoiceDialog: false,
			ExtendInvoiceDialog: false,
			file_list: [],
		};
	},
	components: {
		ActivityTab,
		Chip,
		Files,
		UpdateInvoice,
		// AddPaymentDialog,
		ExtendInvoice,
	},
	methods: {
		addDate() {
			let newDate = MomentJS(this.singleDataInvoice.start_date, "YYYY-MM-DD")
				.subtract(15, "days")
				.format("YYYY-MM-DD");
			return this.formatDate(newDate);
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		pendingBalance() {
			let result = this.singleDataInvoice.payable_amount - this.singleDataInvoice.paid_amount;
			return this.formatMoney(result);
		},
		addOldDate(date) {
			let newDate = MomentJS(date, "YYYY-MM-DD").subtract(15, "days").format("YYYY-MM-DD");
			return this.formatDate(newDate);
		},
		addExtendDate(date) {
			let newDate = MomentJS(date, "YYYY-MM-DD").subtract(15, "days").format("YYYY-MM-DD");
			return this.formatDate(newDate);
		},
		refreshFunction() {
			(this.ExtendInvoiceDialog = false), this.singleInvoice(), this.invoiceExtendActivity();
		},
		getStatusText() {
			if (this.singleDataInvoice.status == 1) {
				return "Raised";
			}
			if (this.singleDataInvoice.status == 2) {
				return "Paid";
			}
			if (this.singleDataInvoice.status == 3) {
				return "Partially Paid";
			}
			if (this.singleDataInvoice.status == 4) {
				return "Pending For Issuance";
			}
			if (this.singleDataInvoice.status == 5) {
				return "Overdue Payment";
			}
			if (this.singleDataInvoice.status == 6) {
				return "Draft";
			}
			if (this.singleDataInvoice.status == 7) {
				return "Overdue For Issuance";
			}
			if (this.singleDataInvoice.status == 8) {
				return "Issued";
			}
		},

		getStatusColor() {
			if (this.singleDataInvoice.status == 1) {
				return "blue";
			}
			if (this.singleDataInvoice.status == 2) {
				return "green";
			}
			if (this.singleDataInvoice.status == 3) {
				return "orange";
			}
			if (this.singleDataInvoice.status == 4) {
				return "red";
			}
			if (this.singleDataInvoice.status == 5) {
				return "#a020f0";
			}
			if (this.singleDataInvoice.status == 6) {
				return "cyan";
			}
			if (this.singleDataInvoice.status == 7) {
				return "#191970";
			}
			if (this.singleDataInvoice.status == 8) {
				return "#008080";
			}
		},

		pageTitle() {
			return "InVoice Details";
		},

		singleInvoice() {
			this.$store
				.dispatch(GET, { url: `invoice/${this.invoiceId}` })
				.then((data) => {
					this.singleDataInvoice = data.invoice;
					this.salesPerson = data.salesPerson;
					this.customerId = toSafeInteger(this.singleDataInvoice.project.customer?.id);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editInvoice() {
			this.UpdateInvoiceDialog = true;
			this.$store
				.dispatch(GET, { url: `invoice/${this.invoiceId}` })
				.then((data) => {
					this.singleDataInvoice = data.invoice;
					this.customerId = toSafeInteger(this.singleDataInvoice.project.customer?.id);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		addPayment() {
			this.UpdateInvoiceDialog = true;
			this.$store
				.dispatch(GET, { url: `invoice/${this.invoiceId}` })
				.then((data) => {
					this.singleDataInvoice = data.invoice;
					this.customerId = toSafeInteger(this.singleDataInvoice.project.customer?.id);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		extendInvoice() {
			this.ExtendInvoiceDialog = true;
			this.$store
				.dispatch(GET, { url: `invoice/${this.invoiceId}` })
				.then((data) => {
					this.singleDataInvoice = data.invoice;
					this.customerId = toSafeInteger(this.singleDataInvoice.project.customer?.id);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		invoiceActivity() {
			this.$store
				.dispatch(GET, { url: `invoice-log/${this.invoiceId}` })
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		invoiceExtendActivity() {
			this.$store
				.dispatch(GET, { url: `invoice-extention-log/${this.invoiceId}` })
				.then((data) => {
					this.ExtendlogData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	computed: {
		invoiceTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.invoiceTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "invoice";
			},
		},
	},
	mounted() {
		if (!this.getPermission("invoice:view") && this.invoiceId > 0) {
			this.$router.replace({
				name: "invoice",
				query: { t: new Date().getTime() },
			});
		}
		this.singleInvoice();
		this.invoiceActivity();
		this.invoiceExtendActivity();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Invoice", disabled: false, href: "/invoice" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.invoiceId = toSafeInteger(this.$route.params?.id);
	},
};
</script>
